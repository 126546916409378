import { SeverityLevel } from "@sentry/nextjs";

enum SentryErrorEvent {
  AmazonApiInvalidDataError = "AmazonApiInvalidDataError",
  AmazonPricePushError = "AmazonPricePushError",
  AnomalyDetectionAlertError = "AnomalyDetectionAlertError",
  BigCommercePricePushError = "BigCommercePricePushError",
  ClientApiError = "ClientApiError",
  ClientAppError = "ClientAppError",
  DataScienceAutomationApiError = "DataScienceAutomationApiError",
  DataScienceRestApiError = "DataScienceRestApiError",
  DbtTriggerError = "DbtTriggerError",
  ExpressRestApiError = "ExpressRestApiError",
  FrontendAuthError = "FrontendAuthError",
  GlobalErrorBoundaryHit = "GlobalErrorBoundaryHit",
  HandleApiError = "HandleApiError",
  ImportLaikaCompetitorDataError = "ImportLaikaCompetitorDataError",
  IntervalActionError = "IntervalActionError",
  LaikaPricePushError = "LaikaPricePushError",
  MiddlewareError = "MiddlewareError",
  MissingBrandForUser = "MissingBrandForUser",
  MissingUser = "MissingUser",
  PostgresListenerConnectionError = "PostgresListenerConnectionError",
  PricePlanGenerationError = "PricePlanGenerationError",
  PricePlanStatusValidationError = "PricePlanStatusValidationError",
  PricePushTriggerError = "PricePushTriggerError",
  PriceRulesEngineError = "PriceRulesEngineError",
  RechargeApiError = "RechargeApiError",
  RefreshPricePlanError = "RefreshPricePlanError",
  RequestPricePlanAnalysesError = "RequestPricePlanAnalysesError",
  RiversandPricePushError = "RiversandPricePushError",
  RouteErrorBoundaryHit = "RouteErrorBoundaryHit",
  RouteErrorInvalidTab = "RouteErrorInvalidTab",
  SendgridEmailError = "SendgridEmailError",
  ShopifyPricePushError = "ShopifyPricePushError",
  SlackMessageDeliveryError = "SlackMessageDeliveryError",
  Test = "Test",
  TestBrandDataIngestionError = "TestBrandDataIngestionError",
  TrpcInternalApiError = "TrpcInternalApiError",
  TrpcPublicApiError = "TrpcPublicApiError",
  UpdateExperimentDetailsPriceLiveStatusError = "UpdateExperimentDetailsPriceLiveStatusError",
  UserSignupError = "UserSignupError",
  UserSignupErrorMissingEmail = "UserSignupErrorMissingEmail",
  VercelCronJobError = "VercelCronJobError",
}

const ErrorSeverityMap: { [key in SentryErrorEvent]: SeverityLevel } = {
  AmazonApiInvalidDataError: "error",
  AmazonPricePushError: "error",
  AnomalyDetectionAlertError: "error",
  BigCommercePricePushError: "error",
  ClientApiError: "error",
  ClientAppError: "error",
  DataScienceAutomationApiError: "error",
  DataScienceRestApiError: "error",
  DbtTriggerError: "error",
  ExpressRestApiError: "error",
  FrontendAuthError: "error",
  GlobalErrorBoundaryHit: "fatal",
  HandleApiError: "error",
  ImportLaikaCompetitorDataError: "error",
  IntervalActionError: "error",
  LaikaPricePushError: "error",
  MiddlewareError: "error",
  MissingBrandForUser: "error",
  MissingUser: "error",
  PostgresListenerConnectionError: "error",
  PricePlanGenerationError: "fatal",
  PricePlanStatusValidationError: "fatal",
  PricePushTriggerError: "fatal",
  PriceRulesEngineError: "fatal",
  RechargeApiError: "error",
  RefreshPricePlanError: "fatal",
  RequestPricePlanAnalysesError: "error",
  RiversandPricePushError: "error",
  RouteErrorBoundaryHit: "fatal",
  RouteErrorInvalidTab: "error",
  SendgridEmailError: "error",
  ShopifyPricePushError: "fatal",
  SlackMessageDeliveryError: "error",
  Test: "error",
  TestBrandDataIngestionError: "error",
  TrpcInternalApiError: "error",
  TrpcPublicApiError: "error",
  UpdateExperimentDetailsPriceLiveStatusError: "error",
  UserSignupError: "fatal",
  UserSignupErrorMissingEmail: "fatal",
  VercelCronJobError: "error",
};

export function getErrorSeverity(
  sentryErrorEvent: SentryErrorEvent,
): SeverityLevel {
  return ErrorSeverityMap[sentryErrorEvent];
}

export function getErrorFingerprint(
  err: Error,
  sentryErrorEvent: SentryErrorEvent,
): string[] {
  if ("message" in err) {
    return [sentryErrorEvent, err.message];
  }

  return [sentryErrorEvent];
}

export default SentryErrorEvent;
